import _ from 'lodash'
import { reject, flow, mapValues, flatMap, map, uniqBy, isPlainObject, isEmpty, endsWith } from 'lodash/fp'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, branch, lifecycle, withProps, withHandlers } from 'recompose'
import Dialog from 'e-submission/components/Dialog'
import { ModalHeader, ModalBody } from 'reactstrap'

import { getAllInvalidField, isInvalidFNA, isInvalidVulnerableCustomer } from 'e-submission/apps/selectors'
import { submitApplication, validateApplication } from 'e-submission/apps/actions'

import { getIcon } from 'e-submission/components/Icon'
import Content from 'e-submission/components/Layout/Content'
import EditCard from 'e-submission/components/Card/EditCard'
import { getToggles } from 'quick-quote/feature-toggles'

const Status = styled.div`
  text-align: center;
  padding: 30px 0px;
  div:last-child {
    padding-top: 30px;
  }
`

const Component = styled(({ className, invalid, invalidFNA, invalidVulnerableCustomer, history, toggle }) => {
  return (
    <Dialog className={className} isOpen={!_.isEmpty(invalid) || invalidFNA || invalidVulnerableCustomer}>
      <ModalHeader toggle={toggle}>
        <div>กรอกข้อมูลไม่ครบ</div>
      </ModalHeader>
      <ModalBody>
        <Status>
          {getIcon('formInvalid')}
          <div>
            ไม่สามารถสร้างเอกสารได้
            <br />
            <b>กรุณากลับไปแก้ไขข้อมูลด้านล่างให้สมบูรณ์</b>
          </div>
        </Status>
        <Content>
          {_.map(invalid, ({ fields, label }, pageId) => (
            <EditCard key={pageId} title={label} onClick={() => history.push(pageId)}>
              <ul>
                {_.map(fields, (label, index) => (
                  <li key={index}>{label}</li>
                ))}
              </ul>
            </EditCard>
          ))}
          {invalidFNA && (
            <EditCard
              key="FNA"
              title={'แบบสอบถามเพื่อวิเคราะห์ความต้องการซื้อผลิตภัณฑ์ประกันชีวิต'}
              btnText="กลับสู่หน้าแรก"
              onClick={() => history.push('/')}
            >
              <ul>
                <li>ไม่สามารถดำเนินการต่อได้ เนื่องจากไม่มีข้อมูล FNA</li>
                <li>กรุณาเริ่มการขายใหม่</li>
              </ul>
            </EditCard>
          )}
          {invalidVulnerableCustomer && (
            <EditCard
              key="VC"
              title={'แบบสอบถามความเข้าใจ กลุ่มคำถามเปราะบาง'}
              btnText="กลับสู่หน้าแรก"
              onClick={() => history.push('/')}
            >
              <ul>
                <li>ไม่สามารถดำเนินการต่อได้ เนื่องจากไม่มีข้อมูล กลุ่มคำถามเปราะบาง</li>
                <li>กรุณาเริ่มการขายใหม่</li>
              </ul>
            </EditCard>
          )}
        </Content>
      </ModalBody>
    </Dialog>
  )
})`
  .modal-body {
    padding: 0px 0px 32px 0px;
    background: #f5f5f5;
  }
  ul {
    padding-left: 15px;
    margin-bottom: 10px;
    li {
      padding-bottom: 10px;
    }
  }
`

export default compose(
  withRouter,
  connect(
    (state) => ({
      invalid: getAllInvalidField(state),
      invalidFNA: isInvalidFNA(state),
      invalidVulnerableCustomer: isInvalidVulnerableCustomer(state),
    }),
    (dispatch) => ({
      validateApplication: () => dispatch(validateApplication()),
      submitApplication: () => dispatch(submitApplication()),
    })
  ),
  branch(
    ({ invalid }) => _.isEmpty(invalid),
    lifecycle({
      componentDidMount() {
        this.props.submitApplication()
      },
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.validateApplication()
    },
  }),
  withProps(({ history, invalid }) => ({
    invalid: mapValues(
      ({ sections, label }) => ({
        label,
        fields: flow(
          map(({ id, fields, label, heading }) => {
            if (endsWith(']', id)) {
              return heading
            }

            if (!isEmpty(label)) {
              return label
            }
            const insuredEkyc = fields.filter((field) => field.id == 'remoteSelling.insured.dopaResult')
            const payerEkyc = fields.filter((field) => field.id == 'remoteSelling.payer.dopaResult')
            if (id === 'ekyc' && insuredEkyc.length > 0) {
              return 'กรุณายืนยันตัวตนของผู้เอาประกันภัย'
            } else if (id === 'ekyc' && payerEkyc.length > 0) {
              return 'กรุณายืนยันตัวตนของผู้เอาชำระเบี้ย'
            }

            if (getToggles().ENABLE_EKYC_F2F) {
              const insuredIsRequired = fields.filter((field) => field.id == 'validationEKYC.insured.isRequired')
              const payerIsRequired = fields.filter((field) => field.id == 'validationEKYC.payer.isRequired')

              if (id === 'ekyc' && insuredIsRequired.length > 0) {
                return 'กรุณายืนยันตัวตนของผู้เอาประกันภัย'
              } else if (id === 'ekyc' && payerIsRequired.length > 0) {
                return 'กรุณายืนยันตัวตนของผู้ชำระเบี้ย'
              }
            }

            return { id, fields }
          }),
          reject(isEmpty),
          uniqBy((value) => (isPlainObject(value) ? value.id : value)),
          flatMap((value) => (isPlainObject(value) ? map('label', value.fields) : value))
        )(sections),
      }),
      invalid
    ),
  })),
  withHandlers({
    toggle: ({ history, invalid }) => () => (_.keys(invalid)[0] ? history.push(_.keys(invalid)[0]) : history.goBack()),
  })
)(Component)
