import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, branch, renderNothing, mapProps } from 'recompose'

import { getDialog, isShowDialog } from 'e-submission/apps/selectors'
import {
  hideDialog,
  confirmYes,
  confirmNo,
  setFlagCheckStatus,
  setEDACheckStatusError,
  setEDACheckStatusExpired,
  setAppValue,
  unsetAppValue,
  saveAppData,
  unsetEDACheckStatus,
  submitRecurringPayment,
} from 'e-submission/apps/actions'

import PreviewPDF from './PreviewPDF'
import PreviewMultiplePDF from './PreviewMultiplePDF'
import Signature from './Signature'
import OTP from './OTP'
import Error from './Error'
import Confirmation from './Confirmation'
import ConfirmationEDA from './ConfirmationEDA'
import SubmitPayment from './SubmitPayment'
import Syncing from './Syncing'

export const DIALOG = {
  PreviewPDF,
  PreviewMultiplePDF,
  Signature,
  OTP,
  Error,
  Confirmation,
  ConfirmationEDA,
  SubmitPayment,
  Syncing,
}

/* istanbul ignore next */
export default compose(
  connect(
    (state) => ({
      dialog: getDialog(state),
      isOpen: isShowDialog(state),
    }),
    {
      hideDialog,
      confirmYes,
      confirmNo,
      setFlagCheckStatus,
      setEDACheckStatusError,
      setEDACheckStatusExpired,
      setAppValue,
      unsetAppValue,
      saveAppData,
      unsetEDACheckStatus,
      submitRecurringPayment,
    }
  ),
  branch(({ isOpen, dialog }) => !isOpen || !_.has(DIALOG, dialog.name), renderNothing),
  mapProps(
    ({
      dialog,
      isOpen,
      hideDialog,
      confirmYes,
      confirmNo,
      setFlagCheckStatus,
      setEDACheckStatusError,
      setEDACheckStatusExpired,
      setAppValue,
      unsetAppValue,
      saveAppData,
      unsetEDACheckStatus,
      submitRecurringPayment,
    }) => {
      const Modal = DIALOG[dialog.name] || (() => null)
      const props = {
        ...dialog,
        isOpen,
        hideDialog,
        confirmYes,
        confirmNo,
        toggle: (e) => {
          if (e) {
            e.preventDefault()
          }
          hideDialog()
        },
        setFlagCheckStatus,
        setEDACheckStatusError,
        setEDACheckStatusExpired,
        setAppValue,
        unsetAppValue,
        saveAppData,
        unsetEDACheckStatus,
        submitRecurringPayment,
      }

      return {
        children: <Modal {...props} />,
      }
    }
  )
)(({ children }) => children)
