import { compose, branch, renderNothing } from 'recompose'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getCurrentApp, isLoading } from 'e-submission/apps/selectors'
import { get } from 'lodash/fp'
import { isSelectedRemoteSelling } from 'e-submission/domain/data-model/form/selectors'
import { cancelEvent } from 'quick-quote/remote-selling'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1060;
`
export const Loading = ({ className, isRemoteSelling, message, onClick }) => {
  return isRemoteSelling ? (
    <Wrapper id="main-wrapper" className={className}>
      <div id="esub-loader">
        {!message && <div></div>}
        <h6 className="status">
          <div id="spinner" />
          {message && <span>{message}</span>}
        </h6>
        {message ? (
          <button className="btn" onClick={onClick}>
            ยกเลิกคำสั่ง
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </Wrapper>
  ) : (
    <Wrapper id="main-wrapper" className={className}>
      <div id="loader">
        <h6 className="status"></h6>
        <div id="spinner" />
      </div>
    </Wrapper>
  )
}

export default compose(
  connect(
    (state) => {
      const isRemoteSelling =
        get('quickQuoteInterface.selectedRemoteSelling', state) || isSelectedRemoteSelling(getCurrentApp(state))

      return { showLoading: isLoading(state), isRemoteSelling, message: get('ui.loadingMessage', state) }
    },
    (dispatch) => ({
      onClick: () => dispatch(cancelEvent()),
    })
  ),
  branch(({ showLoading }) => !showLoading, renderNothing)
)(Loading)
