import moment from 'moment'
import styled from 'styled-components'
import { Progress } from 'reactstrap'
import { Flex, Box } from 'rebass'
import _Card, { BaseDetailBox, RiderTags, NameBox } from '../../index'
import NumberInput from 'react-number-format'
import Icon from 'e-submission/components/Icon'
import { totalPremiumPlusLumpSumTopUp } from 'e-submission/apps/selectors'
import { getReceiptAmount } from 'e-submission/domain/data-model/form/selectors'
import { APP_STATUS, PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'

const fontSize = '0.8rem'
const Card = _Card.extend`
  &:hover {
    cursor: pointer;
  }
  padding: 15px 10px;

  .isOnOtp {
    font-weight: bold;
    color: ${({ theme }) => theme.variables['$color-crimson']};
  }

  &.failed {
    border-top: 1px solid ${({ theme }) => theme.variables['$brand-failed']};
    border-bottom: 1px solid ${({ theme }) => theme.variables['$brand-failed']};
    ${({ theme }) => theme.media.md`
      border-left: 1px solid ${({ theme }) => theme.variables['$brand-failed']};
      border-right: 1px solid ${({ theme }) => theme.variables['$brand-failed']};
    `}
    img {
      width: 50px;
      height: 50px;
      margin-right: -10px;
      margin-left: -10px;
      margin-top: -10px;
    }
    .policy-detail {
      border-top: solid 1px ${({ theme }) => theme.variables['$brand-failed']};
    }
  }
  .policy-detail {
    background: ${({ theme }) => theme.variables['$color-gray--lighter']};
    margin-left: -56px;
    margin-right: -16px;
    margin-bottom: -15px;
    padding: 15px 10px 25px 25px;
    font-size: ${fontSize};
    .policy-footer {
      color: ${({ theme }) => theme.variables['$color-dusty-gray']};
      * {
        line-height: 1;
      }
    }
    .icon-check {
      width: ${fontSize};
      height: ${fontSize};
      margin-right: 8px;
      border-radius: 50%;
      background: ${({ theme }) => theme.variables['$brand-success']};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 70%;
        path {
          fill: white;
        }
      }
    }
    .hr {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      background: ${({ theme }) => theme.variables['$color-gray']};
    }
  }
`

const Percentage = styled(Box)`
  width: 43px;
  font-weight: bold;
  text-align: center;
  padding-right: 10px;

  svg {
    path,
    g {
      fill: ${({ theme, type }) => theme.variables[`$brand-${type}`]} !important;
    }
  }
`

export const Details = styled(Box).attrs({
  w: 1,
})`
  padding: 0 5px;
  & > div {
    margin: 0 -5px;
    & + div {
      margin-top: 5px;
    }
  }

  .policyBasicDetail {
    padding-bottom: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.variables['$color-mineshaft']};
  }
`

const PolicyId = BaseDetailBox.extend`
  font-size: ${({ theme }) => theme.variables['$font-size-navigation']};
  font-weight: bold;
  padding: 0 12px;
  color: white;
  text-transform: uppercase;
  background-color: ${({ theme, type }) => theme.variables[`$brand-${type}`]};
`

export const AutoSubmitLabel = styled(({ className, ...props }) => <span className={className}>Auto Submit</span>)`
  color: ${({ theme, type }) => theme.variables[`$brand-${type}`]};
  display: inline-block;
  font-size: 0.75rem;
  padding: 0 7px;
  border: solid 1px currentColor;
  border-radius: 1rem;
  align-self: center;
`

export const RemoteSellingLabel = styled(({ className, ...props }) => (
  <span className={className}>Remote Selling</span>
))`
  color: ${({ theme, type }) => theme.variables[`$brand-${type}`]};
  display: inline-block;
  font-size: 0.75rem;
  padding: 0 7px;
  margin-left: 5px;
  border: solid 1px currentColor;
  border-radius: 1rem;
  align-self: center;
`
const DetailProgress = styled(Progress)`
  & > div {
    height: 4px;
  }
`

const PaymentLabel = ({ type }) => {
  switch (type) {
    case PAYMENT_TYPE.CASH:
      return <span>Cash</span>
    case PAYMENT_TYPE.EDC:
      return <span>EDC</span>
    case PAYMENT_TYPE.CREDITCARD:
      return <span>Credit Card</span>
    default:
      return <span>Cash</span>
  }
}
const ApplicationIcon = ({ status }) => {
  switch (status) {
    case APP_STATUS.SUBMITTED:
      return <Icon.padlock />
    case APP_STATUS.SIGNED:
      return <Icon.agreement />
    case APP_STATUS.SUBMITTING:
      return <div className="spinner" />
    case APP_STATUS.FAILED:
      return <Icon.error />
    default:
      return <Icon.illustrate />
  }
}

export const PolicyDetail = ({ policy, createdAt, lastModified, children }) =>
  policy && (
    <div className="policy-detail">
      {children ? (
        children
      ) : (
        <Flex wrap column>
          <Box w={1}>
            {policy.documents.map(({ text }, index) => (
              <Flex key={index} align="center">
                <span className="icon-check">
                  <Icon.check />
                </span>
                <span>{text}</span>
              </Flex>
            ))}
          </Box>
        </Flex>
      )}
      <div className="hr" />
      <Flex className="policy-footer" wrap>
        <Box w={1}>Agent: {policy.agent}</Box>
        <Box w={1}>Created: {moment(createdAt).format('DD/MM/YY HH:mm')}</Box>
        <Box w={1}>Updated: {moment(lastModified).format('DD/MM/YY HH:mm')}</Box>
        <Box w={1}>
          {policy.receipts.map((receipt, index) => (
            <div key={index}>
              <PaymentLabel {...receipt} />
              &nbsp;
              <NumberInput
                displayType="text"
                suffix=" บ."
                thousandSeparator={true}
                decimalPrecision={2}
                value={getReceiptAmount(receipt)}
              />
              &nbsp;
              <span>{moment(receipt.createdAt).format('DD/MM/YY HH:mm')}</span>
            </div>
          ))}
        </Box>
      </Flex>
    </div>
  )

const ApplicationCard = ({
  policy,
  onClick,
  policyId,
  name,
  status,
  percent,
  createdAt,
  lastModified = createdAt,
  mobileNumber,
  productName,
  riders,
  totalPremium,
  lumpSumTopUp,
  disabled,
  isOnOtp,
  children,
  statusSigned,
  autoSubmission,
  remoteSellingStatus,
}) => {
  return (
    <Card w={1} onClick={onClick} disabled={disabled} className={status} data-status={status}>
      <Percentage type={statusSigned ? statusSigned : status}>
        <ApplicationIcon status={status} />
      </Percentage>
      <Details>
        <Flex>
          <NameBox>{name || '-'}</NameBox>
          {autoSubmission ? <AutoSubmitLabel type={statusSigned ? statusSigned : status} /> : null}
          {remoteSellingStatus ? <RemoteSellingLabel type={statusSigned ? statusSigned : status} /> : null}
        </Flex>
        <Flex wrap className="policyBasicDetail">
          <Box>
            <PolicyId type={statusSigned ? statusSigned : status}>{policyId ? `#${policyId}` : status}</PolicyId>
          </Box>
          <BaseDetailBox flex="1 0 auto" label="แก้ไข">
            {lastModified
              ? new Date(lastModified)
                  .toLocaleDateString('th', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
                  .replace('วัน', '')
              : '-'}
          </BaseDetailBox>
          {mobileNumber && (
            <BaseDetailBox label="โทร">
              <NumberInput value={mobileNumber} format={'###-###-####'} displayType="text" />
            </BaseDetailBox>
          )}
          {status === APP_STATUS.SIGNED && isOnOtp && (
            <BaseDetailBox className="isOnOtp">ไม่ได้ยืนยันตัวตน</BaseDetailBox>
          )}
        </Flex>
        <Flex style={{ marginBottom: '15px' }} wrap>
          <BaseDetailBox>
            <strong>{productName || '-'}</strong>
          </BaseDetailBox>
          <BaseDetailBox flex="1 1 auto">
            <RiderTags riders={riders} lumpSumTopUp={lumpSumTopUp} />
          </BaseDetailBox>
          <BaseDetailBox label="งวดแรก">
            {totalPremium ? (
              <NumberInput
                value={totalPremiumPlusLumpSumTopUp(totalPremium, lumpSumTopUp)}
                displayType="text"
                thousandSeparator
                suffix=" บาท"
                decimalPrecision={2}
              />
            ) : (
              '-'
            )}
          </BaseDetailBox>
        </Flex>
        {status === APP_STATUS.FAILED && (
          <PolicyDetail policy={policy} createdAt={createdAt} lastModified={lastModified} />
        )}
        {children}
      </Details>
    </Card>
  )
}

export default ApplicationCard
