import _ from 'lodash'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import { ModalHeader, ModalBody, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import { getIcon } from 'e-submission/components/Icon'
import { withRouter } from 'react-router-dom'
import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'
import { ErrorMessageArea } from './ErrorMessageArea'

const Heading = styled.h4`
  font-weight: bold;
`

const ERROR_STATE = {
  noInternet: {
    description: 'กรุณาต่อ Internet',
    icon: 'offline',
    toggle: (history) => {
      return history
    },
  },
  failed: {
    description: 'กรุณารอสักครู่แล้วลองอีกครั้ง',
    icon: 'error',
    toggle: (history) => {
      return history
    },
  },
  failedSignedNewRate: {
    title: 'เอกสารหมดอายุ',
    description: 'เนื่องจากเบี้ยประกันมีการเปลี่ยนแปลง',
    caption: 'กรุณาสร้างเอกสารใหม่',
    icon: 'formInvalid',
    retryText: 'กลับ',
    onRetry: (history) => {
      history.replace('/e-submission/list')
    },
    toggle: (history) => {
      history.replace('/e-submission/list')
    },
  },
  failedNewRate: {
    title: 'เอกสารหมดอายุ',
    description: 'เนื่องจากเบี้ยประกันมีการเปลี่ยนแปลง',
    caption: 'กรุณาสร้างเอกสารใหม่',
    icon: 'formInvalid',
    retryText: 'กลับ',
    toggle: (history) => {
      return history
    },
  },
  unauthorized: {
    caption: 'ยืนยันตัวตนผิดพลาด',
    heading: 'Session Timeout!',
    description: 'กรุณากดปุ่มออกจากระบบ แล้วเข้าสู่ระบบเพื่อใช้งานอีกครั้ง',
    title: 'กรุณาออกระบบ และกลับเข้าสู่ระบบใหม่อีกครั้ง',
    icon: 'keys',
    retryText: 'ปิดหน้านี้',
    toggle: (history) => {
      return history
    },
  },
  invalidPermission: {
    title: MESSAGES.NOT_ALLOW_ESUBMISSION.TITLE,
    description: MESSAGES.NOT_ALLOW_ESUBMISSION.DESCRIPTION,
    caption: '',
    icon: 'keys',
    retryText: 'ปิด',
    toggle: (history) => {
      return history
    },
  },
  failedPayment: {
    title: 'ไม่สามารถทำรายการได้',
    description: 'เนื่องจากไม่สามารถทำธุรกรรมได้',
    caption: 'กรุณาติดต่อธนาคารเจ้าของบัตร',
    icon: 'creditCardError',
    retryText: 'กลับ',
    toggle: (history) => {
      return history
    },
  },
}

const ErrorState = styled(
  ({
    history,
    toggle = _.noop,
    isOpen,
    className,
    hideDialog,
    caption = 'ไม่สามารถสร้างเอกสารได้',
    title,
    description,
    icon,
    onRetry = _.noop,
    retryText = 'ลองอีกครั้ง',
  }) => (
    <Dialog toggle={toggle} className={className} isOpen={isOpen}>
      <ModalHeader
        toggle={() => {
          toggle(history)
          hideDialog()
        }}
        ss
      >
        {title || description}
      </ModalHeader>
      <ModalBody>
        {getIcon(icon)}
        <div className="text-center">
          <div className="captionDescription">
            {getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? null : <div>{caption}</div>}
            {description === 'กรุณากดปุ่มออกจากระบบ แล้วเข้าสู่ระบบเพื่อใช้งานอีกครั้ง' && (
              <Heading data-testId="auth-error-msg">Session Timeout!</Heading>
            )}
            <div>
              <strong>{description}</strong>
            </div>
          </div>
          <Button
            color="primary"
            onClick={() => {
              onRetry(history)
              hideDialog()
            }}
          >
            {retryText}
          </Button>
        </div>
        {icon === 'error' && caption && getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? (
          <ErrorMessageArea errorMsg={caption} />
        ) : null}
      </ModalBody>
    </Dialog>
  )
)`
  .modal-body {
    padding: 25px 0;
    .captionDescription {
      padding: 25px 0 40px 0;
    }
  }
`

export default compose(
  withRouter,
  withProps(({ type }) => _.get(ERROR_STATE, type))
)(ErrorState)
