import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'

const CustomDialog = styled(Dialog)`
  ${(props) => {
    const { cssClass, theme } = props
    return `
      .modal-title {
        flex: ${cssClass === 'modal-title-wide' ? '0 0 93%' : '1 0 auto'};
        font-weight: bold;
        text-align: center;
        color: ${theme.variables['$brand-primary']};
        width: fit-content;
      }
      .modal-body {
        padding: 60px;
        text-align: center;
        background: white;

        @media (max-width: 767.98px) {
          padding: 30px;
        }
      }
      .info {
        font-weight: bold;
        color: ${theme.variables['$brand-primary']};
      }
      .modal-footer {
        justify-content: space-between;
        &.center {
          justify-content: center;
        }
        border-top: none;
      }
    `
  }}
`

const ConfirmationEDA = ({
  isOpen,
  className,
  confirmYes,
  confirmNo,
  message: { title = '', body = '', cssClass = '' },
  onlyConfirm,
  confirmText,
  customConfirmYes,
  customConfirmWithDispatchName = '',
  setFlagCheckStatus,
  setEDACheckStatusError,
  setEDACheckStatusExpired,
  setAppValue,
  unsetAppValue,
  saveAppData,
  unsetEDACheckStatus,
  submitRecurringPayment,
}) => (
  <CustomDialog
    className={className}
    modalClassName="default-bootstrap"
    isOpen={isOpen}
    backdrop={true}
    cssClass={cssClass}
  >
    <ModalHeader tag="div">{title}</ModalHeader>
    <ModalBody dangerouslySetInnerHTML={{ __html: body }} />
    <ModalFooter className={onlyConfirm ? 'center' : ''}>
      {onlyConfirm ? null : (
        <Button color="primary" outline onClick={confirmNo}>
          ยกเลิก
        </Button>
      )}
      <Button
        color="primary"
        onClick={() => {
          if (customConfirmWithDispatchName === 'SET_EDA_CHECK_STATUS') {
            setFlagCheckStatus()
            confirmYes()
          } else if (customConfirmWithDispatchName === 'EDA_CHECK_STATUS_EXPIRED') {
            setEDACheckStatusExpired()
            confirmYes()
          } else if (customConfirmWithDispatchName === 'EDA_CHECK_STATUS_ERROR') {
            setEDACheckStatusError()
            confirmYes()
          } else if (customConfirmWithDispatchName === 'EDA_CHECK_STATUS_SUCCESS') {
            unsetAppValue('ATP.checkStatusEDA')
            setAppValue('ATP.paid', true)
            saveAppData()

            // We need to submit recurring payment here after EDA check status success
            // In order to fix no document issue
            submitRecurringPayment()
            confirmYes()
          } else if (customConfirmWithDispatchName === 'EDA_CHECK_STATUS_REJECTED') {
            unsetAppValue('ATP.checkStatusEDA')
            saveAppData()
            confirmYes()
          } else if (customConfirmWithDispatchName === 'EDA_CONFIRM_PREVIOUS_SUCCESS') {
            unsetEDACheckStatus()
            confirmYes()
          } else {
            !!customConfirmYes ? customConfirmYes(confirmYes) : confirmYes()
          }
        }}
      >
        {confirmText ? confirmText : 'ยืนยัน'}
      </Button>
    </ModalFooter>
  </CustomDialog>
)

export default ConfirmationEDA
