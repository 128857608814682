import styled from 'styled-components'
import { Flex } from 'rebass'
import { connect } from 'react-redux'
import { getQuickQuoteInsuredInformation, totalPremiumPlusLumpSumTopUp } from 'e-submission/apps/selectors'
import { BaseDetailBox, RiderTags, NameBox } from './index'
import { contentMaxWidth } from 'e-submission/components/mixins'
import NumberInput from 'react-number-format'
import CONSTANTS from 'core/data-model/constants/product'
import MESSAGES from 'core/data-model/constants/messages'

const ProductDetail = styled(Flex)`
  padding-top: 10px;
`

export const firstPeriod = (productName) => {
  let title = MESSAGES.FIRST_PAYMENT_TITLE

  if (productName === CONSTANTS.IINVEST_PRODUCT_NAME) {
    title = MESSAGES.ONE_TIME_PAYMENT_TITLE
  }
  return title + ': '
}

const InsuredInformationCard = styled(({ className, name, productName, riders, totalPremium, lumpSumTopUp }) => (
  <div className={className}>
    <Flex justify="space-between" wrap>
      <NameBox flex={['1 0 100%', null, null, '0 1 auto']}>{name || '-'}</NameBox>
      <ProductDetail wrap flex={['1 0 100%', null, null, '0 1 auto']}>
        <BaseDetailBox>
          <strong className="productName">{productName || '-'}</strong>
        </BaseDetailBox>
        <BaseDetailBox flex="1 1 auto">
          <RiderTags riders={riders} lumpSumTopUp={lumpSumTopUp} />
        </BaseDetailBox>
        <BaseDetailBox>
          <span className="firstPeriod">{firstPeriod(productName)}</span>
          {totalPremium ? (
            <NumberInput
              value={totalPremiumPlusLumpSumTopUp(totalPremium, lumpSumTopUp)}
              displayType="text"
              suffix=" บาท"
              thousandSeparator
              decimalPrecision={2}
            />
          ) : (
            '-'
          )}
        </BaseDetailBox>
      </ProductDetail>
    </Flex>
  </div>
)).attrs({
  size: 'xl',
})`
  padding: 0 1rem;
  margin-top: 5px;
  ${NameBox}, > ${Flex} {
    padding-bottom: 10px;
  }
  ${NameBox} + ${ProductDetail} {
    border-top: 1px solid ${({ theme }) => theme.variables['$color-silver']};
    ${({ theme }) => theme.media.lg`
    border-top: none;
    padding: 0;
    `};
  }
  > ${Flex} {
    ${contentMaxWidth} border-bottom: 1px solid ${({ theme }) => theme.variables['$color-gray--darkest']};
  }
  .firstPeriod {
    color: ${({ theme }) => theme.variables['$color-dusty-gray']};
  }
`

export default connect(getQuickQuoteInsuredInformation)(InsuredInformationCard)
