import { connect } from 'react-redux'
import { compose } from 'recompose'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import { getToggles } from 'quick-quote/feature-toggles'
import Icon from 'e-submission/components/Icon'
import Dialog from 'e-submission/components/Dialog'
import { getFileWithName } from 'e-submission/apps/selectors'
import { sendPreviewDocument, showSignPad } from 'e-submission/apps/actions'

import viewerUrl from 'file-loader?name=pdf-viewer/web/viewer.html&emitFile=false!e-submission/pdf-viewer/web/viewer.html'

const onLoadIframe = (evt, doc) => {
  const iframe = evt.target
  const iframeDocument = iframe.contentWindow.document
  const toolbar = iframeDocument.querySelector('.toolbar')
  const content = iframeDocument.querySelector('#viewerContainer')

  toolbar.style.visibility = 'hidden'
  content.style.top = 0

  iframeDocument.body.setAttribute('data-doc-name', doc.name)
  iframeDocument.body.setAttribute('data-readonly', doc.readOnly.toString())
  if (getToggles().ENABLE_ECBR_WATERMARK) {
    iframeDocument.body.setAttribute('data-watermark', 'true')
  }
}

export const Component = ({
  toggle,
  className,
  isOpen,
  hideDialog,
  url,
  document,
  onSign,
  sendCommand,
  selectedRemoteSelling,
}) => (
  <Dialog toggle={toggle} className={[className, 'az-dialog--preview-pdf'].join(' ')} isOpen={isOpen}>
    <ModalHeader tag="div">
      <div className="action action--left">
        <div className="btn-left" onClick={hideDialog}>
          <Icon.arrowLeft className="icon" />
        </div>
      </div>
      <div className="title">{document.text}</div>
      <div className="action action--right" />
    </ModalHeader>
    <ModalBody className={`modal-body--pdf-view modal-body--pdf-view--${document.name}`}>
      <iframe src={`${viewerUrl}?file=${url}`} onLoad={(evt) => onLoadIframe(evt, document)} />
    </ModalBody>
    {document.readOnly ? null : (
      <ModalFooter>
        <Button color="primary" onClick={selectedRemoteSelling ? sendCommand : onSign}>
          {selectedRemoteSelling ? 'ส่งเอกสารให้ผู้เอาประกัน' : document.sectionLabel}
        </Button>
      </ModalFooter>
    )}
  </Dialog>
)

export default compose(
  connect(
    (state, { document = {} }) => ({
      url: getFileWithName(state, document.name),
      selectedRemoteSelling: document?.selectedRemoteSelling,
    }),
    (dispatch, { document = {} }) => ({
      onSign: () => dispatch(showSignPad(document)),
      sendCommand: () => {
        const payload = {
          ...document,
          policyId: document.policyId,
          needSignature: document.sectionId !== 'acknowledge',
        }
        dispatch(sendPreviewDocument(payload))
      },
    })
  )
)(Component)
