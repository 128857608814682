import moment from 'moment'
import { getOr, get } from 'lodash/fp'
import { Flex } from 'rebass'
import { compose, withProps, withHandlers } from 'recompose'
import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import Button from 'e-submission/components/Button'
import Content from 'e-submission/components/Layout/Content'
import NumberInput from 'react-number-format'
import { getToggles } from 'quick-quote/feature-toggles'
import { withRouter } from 'react-router-dom'
import store from 'e-submission/store'
import { connect } from 'react-redux'
import { isSelectedRemoteSelling } from 'e-submission/domain/data-model/form/selectors'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { cancelEvent } from 'quick-quote/remote-selling'

const Component = styled(
  ({
    toggle,
    isOpen,
    retryCybsPolling,
    className,
    hideDialog,
    status = 'loading',
    info = [],
    remark = {},
    forceOverruled = false,
  }) => (
    <Dialog id="submit-modal" toggle={toggle} className={`${className} submit-${status}`} isOpen={isOpen} zIndex={1200}>
      <ModalHeader toggle={hideDialog}>
        {(status === '' || status === 'initial') && (
          <div>
            <span>ขออภัย ท่านต้องทำการยืนยันชำระเบี้ยประกัน</span>
          </div>
        )}
      </ModalHeader>
      <ModalBody>
        <Content>
          <div id="loader">
            {status === 'loading' && (
              <div>
                <h6 className="status"></h6>
                <div id="spinner" />
                <label>กำลังชำระเงิน</label>
              </div>
            )}
            {status === 'success' && (
              <div>
                <h6 className="text-danger"></h6>
                <Icon.check />
                <label>ชำระเงินเรียบร้อย</label>
                <div className="content">
                  {info.map(({ label, value }, key) => (
                    <Flex key={key} justify="space-between">
                      <Flex justify="flex-end" flex="0 0 48%">
                        {label}
                      </Flex>
                      <Flex flex="0 0 48%">
                        <b>{value}</b>
                      </Flex>
                    </Flex>
                  ))}
                </div>
              </div>
            )}

            {(status === 'decline' || status === 'error') && (
              <div id="payment-error">
                <Icon.unsuccess />
                <label className="text-danger">
                  {status === 'decline' ? 'การชำระผ่านบัตรไม่สำเร็จ' : 'ระบบชำระเงินขัดข้อง'}
                </label>
                <div className="content">
                  <Flex key="remarkLable" justify="space-between">
                    <Flex justify="flex-start" flex="0 0 90%">
                      <label className="text-primary">ข้อเสนอแนะ</label>
                    </Flex>
                    <Flex flex="0 0 10%"></Flex>
                  </Flex>
                  <Flex key="remarkValue">
                    <Flex justify="flex-start">
                      <p> {remark.value}</p>
                    </Flex>
                    <Flex></Flex>
                  </Flex>
                  {info.map(({ label, value }, key) => (
                    <Flex key={key} justify="space-between">
                      <Flex justify="flex-start" flex="0 0 30%">
                        <span className="text-nowrap">{label}</span>
                      </Flex>
                      <Flex justify="flex-end" flex="0 0 70%">
                        <span className="text-nowrap">{value}</span>
                      </Flex>
                    </Flex>
                  ))}
                </div>
              </div>
            )}

            {(status === '' || status === 'initial') && (
              <div>
                <div className="modal-title">
                  <Icon.cybersourceRetry />
                </div>

                <div className="content">
                  {info.map(({ label, value }, key) => (
                    <Flex key={key} justify="space-between">
                      <Flex justify="flex-start" flex="0 0 25%">
                        {label === 'จำนวนที่ชำระ' ? (
                          <span className="text-primary text-nowrap">
                            <b>{label}</b>
                          </span>
                        ) : (
                          <span className="text-primary text-nowrap">{label}</span>
                        )}
                      </Flex>
                      <Flex justify="flex-end" flex="0 0 50%">
                        {label === 'จำนวนที่ชำระ' ? (
                          <span className="text-primary text-nowrap">
                            <b>{value}</b>
                          </span>
                        ) : (
                          <span className="text-primary text-nowrap">{value}</span>
                        )}
                      </Flex>
                    </Flex>
                  ))}
                </div>
                <hr className="hr-solid-brand-primary" />
                <div>
                  <Button color="primary" onClick={retryCybsPolling}>
                    ลองอีกครั้ง
                  </Button>
                </div>
                {/* <div className="info-message attached-icon form-control-feedback-cybs"> */}
                <div className="validation-message attached-icon info-message form-control-feedback-cybs">
                  {forceOverruled ? (
                    <span>
                      ก่อนกดปุ่มยืนยันการชำระเงิน กรุณาตรวจสอบการตัดเงินจากธนาคารเจ้าของบัตรว่าสำเร็จแล้วผ่าน SMS หรือ
                      แอพพลิเคชั่น
                    </span>
                  ) : (
                    <span>เพื่อทำรายการชำระเงินโดยใช้รหัสอ้างอิงเดิม</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </Content>
      </ModalBody>
      {status === 'success' && (
        <ModalFooter>
          <Button block color="primary" onClick={hideDialog}>
            กลับเพื่อออกใบเสร็จ
          </Button>
        </ModalFooter>
      )}
      {(status === 'decline' || status === 'error') && (
        <ModalFooter>
          <Button block color="primary" onClick={hideDialog}>
            ลองอีกครั้ง
          </Button>
        </ModalFooter>
      )}
    </Dialog>
  )
)`
  .modal-header {
    border-bottom: none;
    position: relative;
    z-index: 20;
  }
  .modal-body {
    background: white;
    button {
      width: 320px;
      padding-left: 40px;
      padding-right: 40px;
    }
    .modal-title {
      padding-bottom: 40px;
    }
  }
  .modal-footer {
    button {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  #loader {
    min-height: auto;
    background: none;
    padding: 0 20px;
    label {
      margin-top: 1rem;
      font-weight: 600;
      font-size: ${({ theme }) => theme.variables['$font-size-h6']};
    }
    svg {
      display: block;
      margin: auto;
    }
    > div {
      width: 100%;
      text-align: center;
    }
    #payment-error {
      width: auto;
    }
  }
  &.submit-loading {
    #loader {
      margin-top: 50px;
    }
    .modal-header button {
      visibility: hidden;
    }
    #spinner {
      margin: auto;
    }
  }
  &.submit-success {
    svg {
      padding: 5px;
      width: 50px;
      height: 50px;
      background: ${({ theme }) => theme.variables['$brand-success']};
      border-radius: 50%;
      max-width: none;
      max-height: none;
      path {
        fill: white;
      }
    }
    label {
      color: ${({ theme }) => theme.variables['$brand-success']};
    }
    .content {
      background: ${({ theme }) => theme.variables['$color-wild-sand']};
      padding: 30px 0;
      margin: 1rem 0;
    }
    @media (max-width: 425px) {
      .value-content {
        white-space: nowrap;
        overflow: hidden;
        div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`
const info = (payment) => [
  {
    label: 'จำนวน',
    value: (
      <NumberInput
        value={get('amount', payment)}
        suffix={getOr('', 'currency', payment).toUpperCase()}
        thousandSeparator
        decimalPrecision={undefined}
        displayType="text"
      />
    ),
  },
  {
    label: 'วันที่',
    value: moment(get('createdAt', payment)).format('DD/MM/YYYY'),
  },
  {
    label: 'Reference No.',
    value: get('approvalCode', payment),
  },
]

const getCybersourceInfoForErrorDecline = (payment) => [
  {
    label: 'จำนวนเงิน',
    value: (
      <NumberInput
        value={get('amount', payment)}
        suffix={getOr('', 'currency', payment).toUpperCase()}
        thousandSeparator
        decimalPrecision={undefined}
        displayType="text"
      />
    ),
  },
  {
    label: 'วันที่',
    value: moment(get('createdAt', payment)).format('DD/MM/YYYY'),
  },
  {
    label: 'เวลา',
    value: moment(get('createdAt', payment)).format('HH:mm:ss'),
  },
  {
    label: 'Reference No.',
    value: get('referenceNumber', payment),
  },
]

const getCybersourceInfoForRetry = (payment) => [
  {
    label: 'หมายเลขกรมธรรม์',
    value: get('policyId', payment),
  },
  {
    label: 'ผลิตภัณฑ์',
    value: get('product', payment),
  },
  {
    label: 'งวดการชำระเบี้ยประกันภัย',
    value: get('selectedModelFactorLabel', payment),
  },
  {
    label: 'Reference No.',
    value: get('referenceNumber', payment),
  },
  {
    label: 'จำนวนที่ชำระ',
    value: (
      <NumberInput
        value={get('amount', payment)}
        suffix={' ' + getOr('', 'currency', payment).toUpperCase()}
        thousandSeparator
        decimalPrecision={undefined}
        displayType="text"
      />
    ),
  },
]
const cybersourceInfo = (status, payment) =>
  status === 'decline' || status === 'error'
    ? getCybersourceInfoForErrorDecline(payment)
    : getCybersourceInfoForRetry(payment)

export default compose(
  connect(
    (state) => ({
      isRemoteSelling:
        get('quickQuoteInterface.selectedRemoteSelling', state) || isSelectedRemoteSelling(getCurrentApp(state)),
      message: get('ui.loadingMessage', state),
    }),
    (dispatch) => ({
      onClick: () => {
        dispatch(cancelEvent())
      },
    })
  ),
  withRouter,
  withProps(({ status, payment, forceOverruled }) => ({
    info: getToggles().ENABLE_CYBERSOURCE ? cybersourceInfo(status, payment) : info(payment),
    remark: {
      label: 'ข้อเสนอแนะ',
      value: get('remark', payment),
    },
    forceOverruled,
  })),
  withHandlers({
    hideDialog: ({ status, hideDialog, history }) => () => {
      if (getToggles().ENABLE_CYBERSOURCE_RETRY && (status === '' || status === 'initial'))
        history.replace(history.location.pathname.replace(`/payment`, ''))
      hideDialog()
    },
    retryCybsPolling: ({ dispatch, payment, history }) => () => {
      // history.push(`${history.location.pathname}/retry`)

      store.dispatch({
        type: 'START_CYBS_RETRY',
        payload: {
          amount: get('amount', payment),
          policyId: get('policyId', payment),
          product: get('product', payment),
          referenceNumber: get('referenceNumber', payment),
          profileId: get('profileId', payment),
          selectedModelFactorLabel: get('selectedModelFactorLabel', payment),
        },
      })
    },
  })
)(Component)
