import { get, includes, flow, flatMap, keyBy, map, forIn, assign } from 'lodash/fp'
import { compose } from 'recompose'
import styled from 'styled-components'
import { ModalHeader, ModalBody } from 'reactstrap'
import { connect } from 'react-redux'
import { Alert } from 'reactstrap'

import { getPreparedAppInformation } from 'e-submission/apps/selectors'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import Content from 'e-submission/components/Layout/Content'
import ApplicationCard, { PolicyDetail } from 'e-submission/components/Card/ApplicationCard'
import { FailedMessage } from 'e-submission/containers/AppList'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'

const getAllDocuments = () => {
  const r = flow(
    flatMap((d) => d),
    map((d) => keyBy((d) => d.name)(d))
  )(DOCUMENT_TYPE)

  let mergeDocs = {}
  forIn((d) => {
    mergeDocs = assign(mergeDocs, d)
  })(r)
  return mergeDocs
}

const getAttachmentMessageError = (invalidAttachments) => {
  const canFixedDocs = ['atp', 'edc', 'bank-book', 'bank-slip', 'additional-doc']
  const invalidCanFixedDocs = []
  const invalidCanNotFixedDocs = []
  const allDocuments = getAllDocuments()

  invalidAttachments.map(({ documentType, documentName }, index) => {
    const codeError = documentType.split('_')[0]
    const messageError =
      codeError != 'additional-doc' ? get(`${codeError}.text`)(allDocuments) : `เอกสารอื่นๆ เพิ่มเติม "${documentName}"`

    if (includes(codeError)(canFixedDocs)) invalidCanFixedDocs.push(messageError)
    else invalidCanNotFixedDocs.push(messageError)
  })

  return { invalidCanFixedDocs, invalidCanNotFixedDocs }
}

export const SyncingStep = styled(({ step, className, invalidAttachments }) => {
  switch (step) {
    case 'uploading':
      return (
        <div className={className}>
          <div className="spinner" />
          <div>
            <Icon.wifi />
            <b>อัพโหลด เอกสาร</b>
          </div>
          <div className="subtitle">กรุณารอสักครู่ อาจใช้เวลานานถ้าไฟล์ใหญ่</div>
        </div>
      )
    case 'submitting':
      return (
        <div className={className}>
          <div className="spinner" />
          <div>
            <Icon.wifi />
            <b>เอกสารอยู่ระหว่างการนําส่ง</b>
          </div>
          <div className="subtitle">กรุณารอสักครู่</div>
        </div>
      )
    case 'invalid-attachments':
      const { invalidCanFixedDocs, invalidCanNotFixedDocs } = getAttachmentMessageError(invalidAttachments)

      return (
        <div>
          {invalidCanFixedDocs.length > 0 && (
            <Alert color={'danger'} isOpen={true} className={className}>
              ตรวจพบข้อมูล/เอกสารไม่สมบูรณ์ กรุณาลองแนบเอกสารดังต่อไปนี้อีกครั้ง แล้วนำส่งอีกครั้ง
              <ol>
                {invalidCanFixedDocs.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ol>
            </Alert>
          )}
          {invalidCanNotFixedDocs.length > 0 && (
            <Alert color={'danger'} isOpen={true} className={className}>
              ตรวจพบข้อมูล/เอกสารไม่สมบูรณ์
              <ul>
                {invalidCanNotFixedDocs.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
              <ol>
                <li>กรุณาถ่ายรูปหน้าจอนี้ </li>
                <li>ส่งภาพต่อไปนี้ยังทีม Support ผ่าน LINE หรือติดต่อ 1159 </li>
                <li>รอการติดต่อจากเจ้าหน้าที่</li>
              </ol>
            </Alert>
          )}
        </div>
      )
    case 'failed':
      return (
        <div>
          <FailedMessage />
        </div>
      )
    default:
      return (
        <div className={className}>
          <div className="spinner" />
          <div className="subtitle">กรุณารอสักครู่</div>
        </div>
      )
  }
})`
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 25px;
  svg {
    height: 1.125rem;
    margin-right: 5px;
    margin-top: -5px;
  }
  ul {
    margin-left: 4px;
  }
  .subtitle {
    color: ${({ theme }) => theme.variables['$color-gray--darker']};
  }
  &&.alert {
    text-align: left;
  }
`

const Component = styled(
  ({ toggle, isOpen, className, hideDialog, attachments = [], invalidAttachments = [], ...props }) => (
    <Dialog id="syncing-modal" toggle={toggle} className={className} isOpen={isOpen}>
      <ModalHeader
        toggle={includes(props.step)(['invalid-attachments', 'failed']) ? hideDialog : undefined}
      ></ModalHeader>
      <ModalBody>
        <Content>
          <ApplicationCard
            {...props}
            status={includes(props.step)(['invalid-attachments', 'failed']) ? APP_STATUS.FAILED : props.status}
            policy={null}
          >
            <PolicyDetail {...props}>
              <div className="policy-content">
                <SyncingStep {...props} invalidAttachments={invalidAttachments} />
                <ul>
                  {attachments.map(({ text }, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ul>
              </div>
            </PolicyDetail>
          </ApplicationCard>
        </Content>
      </ModalBody>
    </Dialog>
  )
)`
  ${Content} {
    max-width: 420px;
    flex: 1 1 420px;
  }
  .modal-header {
    border: none;
    background: ${({ theme }) => theme.variables['$color-gray--lighter']};
    .modal-title {
      height: 1.5rem;
    }
  }
  .modal-body {
    background: ${({ theme }) => theme.variables['$color-gray--lighter']};
  }
  .policy-detail {
    padding: 25px 25px 0px 25px;
    .policy-content {
      font-size: 1rem;
      .spinner {
        margin: 25px auto;
      }
    }
  }
  .failed {
    border-bottom: solid 1px ${({ theme }) => theme.variables['$color-gray--lighter']};
  }
`

export default compose(connect(getPreparedAppInformation))(Component)
