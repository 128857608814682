import _ from 'lodash'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, lifecycle, branch, renderNothing, defaultProps } from 'recompose'
import NumberFormat from 'react-number-format'
import { ModalHeader, ModalBody, FormGroup, FormFeedback } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import Icon from 'e-submission/components/Icon'
import { getAppInformation } from 'e-submission/apps/selectors'
import { getIdentityUser } from 'identity/selectors'
import { contentMaxWidth } from 'e-submission/components/mixins'
import Dialog from 'e-submission/components/Dialog'

import { showLoading, hideLoading, setOffOtp, errorOtpAttachment } from 'e-submission/apps/actions'
import RequestWrapper from 'e-submission/domain/data/request-wrapper'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import { getPolicyStatus } from 'e-submission/domain/service/policy'
import { getAppConfig } from 'deploy-env/app-config'

const MAX_OTP_LENGTH = 6
const RETRY_COUNTDOWN = 10000
const COUNTDOWN_INTERVAL = 1000
const COUNTDOWN_TIME = RETRY_COUNTDOWN / COUNTDOWN_INTERVAL

let otpInputRef
const inputWithRef = (props) => <input ref={(ref) => (otpInputRef = ref)} {...props} />
/* istanbul ignore next */
export const _OTPInput = styled(
  ({ className, otpNumber, errorMessage, onChange, onKeyDown, isFocus, onFocus, onBlur, onClick }) => (
    <div className={className}>
      <NumberFormat
        type="tel"
        value={otpNumber}
        allowNegative={false}
        decimalPrecision={0}
        pattern="[0-9]*"
        onChange={onChange}
        maxLength={MAX_OTP_LENGTH}
        format={(value) => value}
        customInput={inputWithRef}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
      <div className="digits" onClick={onClick}>
        {_.assign([...Array(MAX_OTP_LENGTH)].fill(''), otpNumber.split('')).map((value, index) => (
          <OTPDigit
            key={index}
            active={index === otpNumber.length}
            isFocus={isFocus}
            isLast={index + 1 === MAX_OTP_LENGTH && otpNumber.length === MAX_OTP_LENGTH}
          >
            {value}
          </OTPDigit>
        ))}
        <FormFeedback className="validation-message attached-icon text-left">
          <p>{errorMessage}</p>
        </FormFeedback>
      </div>
    </div>
  )
)`
  user-select: none;

  input[type='tel'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    transform: scale(0);
    user-select: auto;
  }

  .digits {
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }
`
/* istanbul ignore next */
const OTPInput = compose(
  withState('isFocus', 'setFocus', false),
  withHandlers({
    onChange: ({ setOtpNumber, sendOtp, errorMessage, setErrorMessage }) => (event, { value }) => {
      setOtpNumber(value, () => {
        if (value.length === MAX_OTP_LENGTH) {
          setTimeout(() => sendOtp(value), 100)
        }
      })
      if (errorMessage) {
        setErrorMessage('')
      }
    },
    onKeyDown: ({ otpNumber }) => () => {
      const range = otpNumber.length * 2
      otpInputRef.setSelectionRange(range, range)
    },
    onFocus: ({ setFocus }) => () => setFocus(true),
    onBlur: ({ setFocus }) => () => setFocus(false),
    onClick: () => () => otpInputRef.focus(),
  })
)(_OTPInput)

/* istanbul ignore next */
export const OTPDigit = styled(({ className, children }) => (
  <div className={className}>
    <span className="digit">{children}</span>
  </div>
))`
  display: inline-block;
  margin: 25px 5px;
  padding: 0 5px;

  @keyframes borderBlink {
    50% {
      border-color: ${({ theme }) => theme.variables['$color-silver']};}
    }
  }

  border-bottom: 3px solid
    ${({ active, theme, isFocus, isLast }) => {
      if ((active && isFocus) || (isLast && isFocus)) {
        return `${theme.variables['$brand-primary']};
        animation: borderBlink 1s ease-in-out infinite;
      `
      } else {
        return `${theme.variables['$color-silver']};`
      }
    }}
  .digit {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
    min-width: calc(2.2rem / 2);
    min-height: calc(2.2rem - 15px);
    font-size: 2.2rem;
    line-height: calc(2.2/4);
  }

  ${({ theme }) => theme.media.md`
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 10px;

    .digit {
      min-width: calc(3rem / 2);
      min-height: calc(3rem - 15px);
      font-size: 3rem;
      line-height: calc(3/4);
    }
  `};
`
let countdownId
/* istanbul ignore next */
const OTPCountdown = compose(
  withState('countdown', 'setCountdown', COUNTDOWN_TIME),
  branch(({ countdown }) => countdown <= 0, renderNothing),
  lifecycle({
    componentDidMount() {
      countdownId = setInterval(() => this.props.setCountdown(this.props.countdown - 1), COUNTDOWN_INTERVAL)
    },
    componentWillUnmount() {
      clearTimeout(countdownId)
    },
  })
)(({ countdown }) => <span>{countdown}</span>)

/* istanbul ignore next */
const OTP = styled(
  ({
    toggle,
    isOpen,
    className,
    otpNumber,
    otpData,
    mobileNumber,
    errorMessage,
    isRetry,
    sendOtp,
    retryOtp,
    setOtpNumber,
    setErrorMessage,
  }) => (
    <Dialog toggle={toggle} isOpen={isOpen} className={className} autoFocus={false}>
      <ModalHeader tag="div">
        <Icon.arrowLeft onClick={toggle} />
        <div>SMS ยืนยันตนผู้เอาประกันภัย</div>
        <div />
      </ModalHeader>
      <ModalBody className="text-center">
        <FormGroup color={errorMessage ? 'danger' : ''}>
          <div className="otpBody">
            <div>กรุณากรอกรหัส SMS OTP ที่ถูกส่งไปเบอร์</div>
            <div>
              <NumberFormat className="mobileNumber" value={mobileNumber} format="###-###-####" displayType="text" />
            </div>
            <OTPInput
              otpNumber={otpNumber}
              sendOtp={sendOtp}
              setOtpNumber={setOtpNumber}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </FormGroup>
        <div className="retry">
          <div className="referenceCode">
            รหัสอ้างอิง: {otpData.referenceCode || '-'} (รหัสนี้มีอายุ {getAppConfig().OTP_TIMEOUT || '2'} นาที)
          </div>
          <a className={isRetry ? 'disabled' : ''} href="#" onClick={retryOtp}>
            ส่งรหัสอีกครั้ง {isRetry && <OTPCountdown />}
          </a>
        </div>
      </ModalBody>
    </Dialog>
  )
)`
  .modal-title {
    display: flex;
    flex: 1 0 100%;
    justify-content: space-between;
    align-items: center;
  }
  .form-group {
    ${contentMaxWidth};
  }
  .otpBody {
    padding: 10px;
    margin-top: 10px;
    .mobileNumber {
      font-size: ${({ theme }) => theme.variables['$font-size-item-highlight']};
    }
    .referenceCode {
      color: ${({ theme }) => theme.variables['$color-mineshaft']};
    }
  }
  .retry {
    font-size: ${({ theme }) => theme.variables['$font-size-navigation']};
    a {
      text-decoration: underline;
      &.disabled {
        pointer-events: none;
        color: ${({ theme }) => theme.variables['$color-gray']};
      }
    }
  }
`

let isTryId
/* istanbul ignore next */
export default compose(
  withRouter,
  defaultProps('mobileNumber', ''),
  withState('otpNumber', 'setOtpNumber', ''),
  withState('errorMessage', 'setErrorMessage', ''),
  withState('otpData', 'setOtpData', {}),
  withState('isRetry', 'setIsRetry', false),
  connect(
    (state) => ({
      ...getAppInformation(state),
      user: getIdentityUser(state),
    }),
    { showLoading, hideLoading, setOffOtp, errorOtpAttachment },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      toggle: () => {
        ownProps.history.replace(ownProps.backUrl)
      },
      getOtpData: async () => {
        dispatchProps.showLoading()
        try {
          const { data: otpData, statusCode } = await RequestWrapper.postRequest(
            `esub/policies/${stateProps.policyId}/otp`,
            stateProps.user
          )
          //TODO: Check what happen if statusCode is success(any 200s) but not 201?.
          // Does it break the logic if statusCode is not 201?
          // If it break the logic we need a better way to handle this(For example - throw error)
          // Even the statusCode is 201 we also need to check payload. (For example - otpData.code === "200")
          if (statusCode === 201) {
            ownProps.setOtpData(otpData)
          }
          otpInputRef.focus()
        } catch (error) {
          if (_.get(error, 'response.status') === 412) {
            dispatchProps.errorOtpAttachment({ type: 'ERROR_OTP_ATTACHMENTS', errors: _.get(error, 'response.data') })
          }

          ownProps.setErrorMessage('เกิดข้อผิดพลาดโปรดลองอีกครั้ง')
        } finally {
          dispatchProps.hideLoading()
        }
      },
      sendOtp: async (otpNumber) => {
        otpInputRef.blur()
        dispatchProps.showLoading()
        try {
          const { statusCode } = await RequestWrapper.patchRequest(
            `esub/policies/${stateProps.policyId}`,
            stateProps.user,
            {
              status: APP_STATUS.SIGNED,
            },
            { 'otp-pincode': otpNumber }
          )
          //TODO: Check what happen if statusCode is success(any 200s) but not 200?.
          // Does it break the logic if statusCode is not 200?
          // If it break the logic we need a better way to handle this(For example - throw error)
          // Even the statusCode is 200 we also need to check that payload is correct before calling setOffOtp.
          // We also need to return something from PATCH esub/policies/${stateProps.policyId}
          if (statusCode === 200) {
            dispatchProps.setOffOtp()
            ownProps.history.replace(ownProps.nextUrl)
          } else {
            ownProps.setErrorMessage('เกิดข้อผิดพลาดโปรดลองอีกครั้ง')
            otpInputRef.focus()
            dispatchProps.hideLoading()
          }
        } catch ({ response }) {
          const policy = await getPolicyStatus(stateProps.user, stateProps.policyId).catch((e) => undefined)
          const policyStatus = _.get(policy, 'status')
          if (policyStatus === APP_STATUS.SIGNED) {
            dispatchProps.setOffOtp()
            ownProps.history.replace(ownProps.nextUrl)
            return
          } else if (response) {
            switch (response.status) {
              case 401:
                ownProps.setErrorMessage('รหัส SMS ไม่ถูกต้อง')
                break
              default:
                ownProps.setErrorMessage('เกิดข้อผิดพลาดโปรดลองอีกครั้ง')
                break
            }
          } else {
            ownProps.setErrorMessage('เกิดข้อผิดพลาดโปรดลองอีกครั้ง')
          }
          otpInputRef.focus()
          dispatchProps.hideLoading()
        }
      },
    })
  ),
  withHandlers({
    retryOtp: ({ getOtpData, setIsRetry, setOtpNumber, setErrorMessage }) => (event) => {
      event.preventDefault()
      setOtpNumber('')
      setErrorMessage('')
      setIsRetry(true)
      getOtpData()
      isTryId = setTimeout(() => setIsRetry(false), RETRY_COUNTDOWN)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getOtpData()
    },
    componentWillUnmount() {
      clearTimeout(isTryId)
      this.props.hideLoading()
    },
  })
)(OTP)
