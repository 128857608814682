import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { analyticsPageViewEvent } from 'analytics/actions'
import { omit, keyBy, isNil, isEmpty, defaultTo, orderBy, size, flow, get, map, sum, isEqual, head } from 'lodash/fp'
import styled, { css } from 'styled-components'
import {
  branch,
  renderComponent,
  compose,
  lifecycle,
  withProps,
  setDisplayName,
  withHandlers,
  withState,
  mapProps,
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass'
import { Alert } from 'reactstrap'

import Button from 'e-submission/components/Button'
import Content from 'e-submission/components/Layout/Content'
import FormSection from 'e-submission/components/Layout/FormSection'
import Card from 'e-submission/components/Card'
import ApplicationCard from 'e-submission/components/Card/ApplicationCard'
import { contentMaxWidth } from 'e-submission/components/mixins'

import { isLoggedIn, isBancAgent } from 'identity/selectors'

import { getToggles } from 'quick-quote/feature-toggles'

import {
  getReplicationStatus,
  getAppList,
  getPreparedAppInformation,
  submittedFlashMessage,
  getProductsPayload,
  getProductsDisplay,
} from 'e-submission/apps/selectors'
import {
  subscribeReplicationStatus,
  unsubscribeReplicationStatus,
  fetchApplication,
  changeCurrentApp,
  hideSubmittedFlashMessage,
  mergeApplication,
} from 'e-submission/apps/actions'

import { REPLICATION_STATUS, APP_STATUS } from 'e-submission/domain/data-model/constants'
import {
  isCordova,
  housekeepingStatusSignedFlow,
  housekeepingStatusDraftedFlow,
  IsRealDevice,
} from 'e-submission/utils'
import { openDf2fApplication } from 'quick-quote/actions'

import { getQueryParam } from 'quick-quote/opty/utils'
import ROUTE_PATH from 'quick-quote/constants/path'
import { getAppByOptyId } from 'e-submission/domain/service/opty'
import { listApplicationSummary } from 'e-submission/domain/service/application'
// import { appsData } from './data'

export const FailedMessage = styled(({ className }) => (
  <Alert color={'danger'} isOpen={true} className={className}>
    ระบบตรวจพบข้อมูล/เอกสารไม่สมบูรณ์
    <ol>
      <li>กรุณากดปิดหน้าจอ X แล้ว กดนำส่ง อีก 1-2 ครั้ง</li>
      <li>หากไม่สามารถนำส่งได้ กรุณาถ่ายรูปหน้าจอนี้</li>
      <li>ส่งภาพต่อไปยังทีม Support ผ่าน LINE หรือ ติดต่อ 1159</li>
      <li>รอการติดต่อกลับจากเจ้าหน้าที่</li>
    </ol>
  </Alert>
))``
export const SubmittingMessage = styled(({ className }) => (
  <Alert color={'warning'} isOpen={true} className={className}>
    เอกสารระหว่างนำส่งหลังบ้าน
    <ul>
      <li>กรุณาอย่าปิด app</li>
      <li>กรุณาอย่า log in บนเครื่องอื่น</li>
    </ul>
  </Alert>
))``

/* istanbul ignore next */
const _ReplicationMessage = styled(({ className, replicationStatus, isLoggedIn }) => {
  if (!isCordova || !isLoggedIn) {
    return null
  }

  let color = 'success'
  let message = ''
  if (replicationStatus === REPLICATION_STATUS.ACTIVE) {
    message = (
      <div>
        กำลังอัพเดทข้อมูล <div id="spinner" />
      </div>
    )
    color = 'info'
  } else if (replicationStatus === REPLICATION_STATUS.IDLE) {
    message = 'อัพเดทข้อมูลสำเร็จ'
  } else if (replicationStatus === REPLICATION_STATUS.OFFLINE) {
    message = 'ยังไม่สามารถอัพเดทข้อมูลได้ กรุณาเปิดเน็ต แล้วรอสักครู่'
    color = 'danger'
  }

  return (
    <Alert color={color} isOpen={true} className={className}>
      {message}
    </Alert>
  )
})`
  #spinner {
    float: right;
    width: 20px;
    height: 20px;
    border-left-color: ${({ theme }) => theme.variables['$color-venice-blue']};
  }
`
/* istanbul ignore next */
const AppContent = Content.extend`
  ${({ theme }) => theme.media.xl`
    ${contentMaxWidth({ size: 'xl' })}
    .appWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ${AppSection} {
        flex: 0 0 32%;
        &:last-child {
          margin-right: 0;
        }
      }
      ${AppSection}:first-child {
        flex: 1 1 100%;
        > div {
          width: 32%;
        }
      }
    }
  `};
`

const AppSection = styled(Box)`
  padding-bottom: 40px;
`
const AppTitle = styled(Box)`
  padding: 5px 5px 5px 15px;
  ${() =>
    getToggles().ENABLE_APP_LIST_STICKY_HEADER &&
    css`
      position: sticky;
      top: 50px;
      background-color: #f5f5f5;
      padding-top: 10px;
    `}
  ${({ theme }) => theme.media.md`
    padding-left: 0;
  `};
  .box {
    display: flex;
  }
  .dot-status {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    margin: 10px;
  }
  .dot-drafted {
    background-color: #5f5f5f;
  }
  .dot-signed {
    background-color: #eb4d33;
  }
  .dot-signed-over {
    background-color: #fcd385;
  }
  .dot-signed-disabled {
    background-color: #e5e5e5;
  }
  .status-msg {
    margin: auto;
    font-size: 0.8rem;
  }
  @media only screen and (min-width: 1200px) {
    .undertitle {
      margin: 8px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .undertitle {
      display: none;
    }
  }
`
/* istanbul ignore next */
const UnderTitleStatus = styled(({ status }) => {
  if (status === APP_STATUS.SIGNED) {
    return (
      <div className="box">
        <div className="box">
          <div className="dot-status dot-signed"></div>
          <div className="status-msg">สถานะปกติ </div>
        </div>
        <div className="box">
          <div className="dot-status dot-signed-over"></div>
          <div className="status-msg">ยกเลิกการขาย ในกรณีรับเงินแล้ว</div>
        </div>
        <div className="box">
          <div className="dot-status dot-signed-disabled"></div>
          <div className="status-msg">ยกเลิกการขาย</div>
        </div>
      </div>
    )
  } else if (status === APP_STATUS.DRAFTED) {
    return (
      <div className="box">
        <div className="box">
          <div className="dot-status dot-drafted"></div>
          <div className="status-msg">กำลังเปิดการขาย </div>
        </div>
        <div className="box">
          <div className="dot-status dot-signed-disabled"></div>
          <div className="status-msg">ยกเลิกการขาย</div>
        </div>
      </div>
    )
  }
  return <div className="undertitle">&nbsp;</div>
})``

const _SubmittedFlashMessage = styled(
  ({ hasSubmittedFlashMessage, hideSubmittedFlashMessage, name, policyId, className }) => (
    <Alert color="success" isOpen={hasSubmittedFlashMessage} toggle={hideSubmittedFlashMessage} className={className}>
      นำส่งเอกสาร #{policyId} ของ {name} เสร็จแล้ว
    </Alert>
  )
)`
  .close {
    font-size: 1rem;
  }
`
const SubmittedFlashMessage = connect(
  (state, { latestSubmitted }) => ({
    ...getPreparedAppInformation(state, { appId: latestSubmitted._id }),
    hasSubmittedFlashMessage: submittedFlashMessage(state),
  }),
  { hideSubmittedFlashMessage }
)(_SubmittedFlashMessage)

const _CustomerDisclaimerMessage = ({ isBancUser }) => {
  return !isBancUser ? null : (
    <Alert color={'info'}>
      ข้อมูลที่ลูกค้าควรรับทราบมีดังนี้
      <ol>
        <li>ลูกค้ามีสิทธิ์ขอยกเลิกกรมธรรม์ ภายใน 15 วันนับแต่วันที่ได้รับกรมธรรม์</li>
        <li>
          สิทธิ์ที่ลูกค้าควรทราบ ในกรณีมีเหตุจำเป็นต้องหยุดส่งเบี้ยประกัน, การกู้เงินตามกรมธรรม์,
          สิทธิ์ของมูลค่าเงินสำเร็จ, สิทธิ์การขยายเวลา
        </li>
        <li>ช่องทางการติดต่อ</li>
      </ol>
    </Alert>
  )
}

export const CustomerDisclaimerMessage = compose(
  connect((state) => ({
    isBancUser: isBancAgent(state),
  }))
)(_CustomerDisclaimerMessage)

/* istanbul ignore next */
export const AppInformation = compose(
  setDisplayName('AppInformation'),
  withRouter,
  connect(getPreparedAppInformation),
  withHandlers({
    onClick: ({ appId, dispatch, history, status, selectedRemoteSelling }) => () => {
      if (selectedRemoteSelling) {
        if (status === APP_STATUS.SIGNED || isNil(status) || status === APP_STATUS.DRAFTED) {
          IsRealDevice && dispatch(openDf2fApplication({ appId }))
        }
        // No action for other status
      } else {
        dispatch(changeCurrentApp(appId))
        if (status === APP_STATUS.SUBMITTED || status === APP_STATUS.SIGNED) {
          history.push('form/submit')
        } else if (isNil(status) || status === APP_STATUS.DRAFTED) {
          history.push('form')
        }
      }
    },
  }),
  withProps((appWithID) => {
    let disabled = false
    let statusSigned = null
    const hasDeeplinkAppId = appWithID?.hasDeeplinkAppId || null

    if (appWithID.status === APP_STATUS.SIGNED) {
      const obj = housekeepingStatusSignedFlow(appWithID)
      disabled = obj.disabled
      statusSigned = obj.statusSigned
    } else if (appWithID.status === APP_STATUS.DRAFTED) {
      const obj = housekeepingStatusDraftedFlow(appWithID)
      disabled = obj.disabled
      statusSigned = obj.statusSigned
    }
    return {
      hasDeeplinkAppId,
      disabled,
      statusSigned,
      remoteSellingStatus: get('selectedRemoteSelling', appWithID),
    }
  })
)(ApplicationCard)

const ReplicationMessage = connect((state, { replicationStatus }) => ({
  isLoggedIn: isLoggedIn(state),
  replicationStatus: replicationStatus || getReplicationStatus(state),
}))(_ReplicationMessage)

const NotFound = styled(({ className, history }) => (
  <AppContent className={className}>
    <FormSection>
      <Flex flex="1 0 100%" column>
        <Box flex="0 0 auto">
          <ReplicationMessage />
        </Box>
        <Flex flex="1 0 auto" column justify="center">
          <div className="sale">
            <span className="title">
              ไม่มีใบคำขอเอาประกัน กรุณา login
              <br />
              หรือเปิดการขายด้วยใบเสนอราคา
            </span>
            <br />
            <Button onClick={() => history.replace('/')}>เปิดการขาย ด้วยใบเสนอราคา</Button>
          </div>
        </Flex>
      </Flex>
    </FormSection>
  </AppContent>
))`
  ${FormSection} {
    display: flex;
    flex: 100%;
  }
  .sale {
    text-align: center;
    padding-bottom: ${({ theme }) => theme.variables['$padding-large-vertical']};
    .title {
      font-size: ${({ theme }) => theme.variables['$font-size-h6']};
      display: inline-block;
      padding-bottom: ${({ theme }) => theme.variables['$padding-large-vertical']};
    }
  }
`

const EmptyApp = styled(({ children, ...props }) => <Card {...props}>{`ยังไม่มีเอกสาร${children}`}</Card>).attrs({
  align: 'center',
  justify: 'center',
})`
  height: 120px;
`

const orderByUpdatedAt = orderBy([flow(get('updatedAt'), defaultTo(0))], ['desc'])

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
const user = {
  access_token: _.get(userInfo, ['access_token'], null),
  profile: {
    agent_code: _.get(userInfo, ['agent_code'], null),
    agent_type: _.get(userInfo, ['agent_type'], null),
  },
}

export default compose(
  connect(
    (state) => {
      return {
        ...getAppList(state),
        submittedFlashMessage: submittedFlashMessage(state),
        products: getProductsPayload(state),
        productDisplays: getProductsDisplay(state),
        user,
      }
    },
    (dispatch) => ({
      subscribeReplicationStatus: () => dispatch(subscribeReplicationStatus()),
      unsubscribeReplicationStatus: () => dispatch(unsubscribeReplicationStatus()),
      listApplication: () => dispatch(fetchApplication()),
      dispatchAnalyticsPageViewEvent: bindActionCreators(analyticsPageViewEvent, dispatch),
      changeCurrentApp: (appId) => {
        dispatch(changeCurrentApp(appId))
      },
      listApplicationSummary: (user) => dispatch(listApplicationSummary(user)),
      mergeApplication: bindActionCreators(mergeApplication, dispatch),
    })
  ),
  withRouter,
  withState('deeplinkAppId', 'setDeeplinkAppId', null),
  mapProps((props) => ({
    ...props,
  })),
  lifecycle({
    async componentDidMount() {
      this.props.listApplication()

      this.props.subscribeReplicationStatus()
      this.props.dispatchAnalyticsPageViewEvent(this.props.location.pathname)

      const search = getQueryParam(_.get(this.props.location, ['search'], null))
      const deeplinkOptyId = _.get(search, ['opty'], null)

      if (search && !_.isEmpty(search) && deeplinkOptyId) {
        const { response } = await getAppByOptyId(this.props.user, deeplinkOptyId)
        const status = _.get(response, ['status'], null)
        const appId = _.get(response, ['applicationId'], null)

        const deeplinkData = {
          [appId]: {
            ...response,
          },
        }
        deeplinkData[appId]._id = appId

        const listApplicationSummaries = await listApplicationSummary(user)

        if (listApplicationSummaries) {
          const appSummaries = flow(map(omit(['_rev'])), keyBy('_id'))(deeplinkData)
          await mergeApplication(appId, appSummaries)
          const app = appSummaries[appId]
          if (app) {
            this.props.changeCurrentApp(appId)
            if (status === APP_STATUS.SUBMITTED || status === APP_STATUS.SIGNED) {
              this.props.history.push('form/submit')
            } else if (isNil(status) || status === APP_STATUS.DRAFTED) {
              this.props.history.push('form')
            }
          }
        }
      }
    },
    componentWillUnmount() {
      this.props.unsubscribeReplicationStatus()
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        this.props.dispatchAnalyticsPageViewEvent(nextProps.location.pathname)
      }
    },
  }),
  withProps((app) => {
    const drafted = app[APP_STATUS.DRAFTED]
    const signed = app[APP_STATUS.SIGNED]
    const submitted = app[APP_STATUS.SUBMITTED]
    const submitting = [].concat(app[APP_STATUS.SUBMITTING] || [], app[APP_STATUS.FAILED] || [])

    const hasSubmitting = !isEmpty(app[APP_STATUS.SUBMITTING])
    const hasFailed = !isEmpty(app[APP_STATUS.FAILED])
    const orderedSubmitted = orderByUpdatedAt(submitted)
    const hasDeeplinkAppId = app?.deeplinkAppId || null

    return {
      hasSubmitting,
      hasFailed,
      sections: [
        {
          title: 'เอกสารระหว่างนำส่ง',
          length: size(submitting),
          list: orderByUpdatedAt(submitting),
          hidden: size(submitting) === 0,
          status: 'submitting',
        },
        {
          title: 'เอกสารระหว่างดำเนินการ',
          length: size(drafted),
          list: orderByUpdatedAt(drafted),
          status: 'drafted',
        },
        {
          title: 'เอกสารเซ็นแล้วรอนำส่ง',
          length: size(signed),
          list: orderByUpdatedAt(signed),
          status: 'signed',
        },
        {
          title: 'เอกสารนำส่งแล้ว',
          length: size(submitted),
          list: orderedSubmitted,
          status: 'submitted',
        },
      ],
      latestSubmitted: head(orderedSubmitted),
      hasDeeplinkAppId,
    }
  }),
  branch(flow(get('sections'), map('length'), sum, isEqual(0)), renderComponent(withRouter(NotFound)))
)(({ hasSubmitting, hasFailed, sections, latestSubmitted, hasDeeplinkAppId }) => (
  <AppContent>
    <FormSection>
      <ReplicationMessage />
      <CustomerDisclaimerMessage />
      {latestSubmitted && <SubmittedFlashMessage latestSubmitted={latestSubmitted} />}
      {hasFailed && <FailedMessage />}
      {hasSubmitting && <SubmittingMessage />}
      <div className="appWrap">
        {sections.map(({ list = [], title, length, hidden = false, status }, index) => (
          <AppSection key={index} style={{ display: hidden ? 'none' : undefined }}>
            <AppTitle>
              <strong>
                {title} ({length})
              </strong>
              {getToggles().ENABLE_DRAFT_HOUSE_KEEPING ? <UnderTitleStatus status={status} /> : undefined}
            </AppTitle>
            {list.length ? (
              list.map(({ _id, selectedRemoteSelling }) => (
                <AppInformation
                  appId={_id}
                  key={_id}
                  selectedRemoteSelling={selectedRemoteSelling}
                  hasDeeplinkAppId={hasDeeplinkAppId}
                />
              ))
            ) : (
              <EmptyApp>{title}</EmptyApp>
            )}
          </AppSection>
        ))}
      </div>
    </FormSection>
  </AppContent>
))
