import { Provider } from 'react-redux'
import ThemeProvider from 'e-submission/components/ThemeProvider'
import RootRoutes from './routes'
import store from './store'

export default (props) => (
  <Provider store={store}>
    <ThemeProvider>
      <RootRoutes {...props} />
    </ThemeProvider>
  </Provider>
)
