import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Loading from 'e-submission/components/Layout/Loading'
import ThemeProvider from 'e-submission/components/ThemeProvider'

const mapStateToProps = (state) => ({
  addVersion: state.appVersion,
})

const mapDispatchToProps = (dispatch) => ({})

const AppContainer = styled.main`
  flex: 1 0 auto;
  display: flex;
`

const MainApp = ({ children }) => (
  <ThemeProvider>
    <AppContainer id="agm-eapp-container">
      <Loading />
      {React.Children.toArray(children)}
    </AppContainer>
  </ThemeProvider>
)

const connector = connect(mapStateToProps, mapDispatchToProps)

const App = compose(connector)(MainApp)

export default App
