import _ from 'lodash'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import App from 'e-submission/containers/App'
import AppList from 'e-submission/containers/AppList'
import AppForm from 'e-submission/containers/AppForm'
import Dialog from 'e-submission/containers/Dialog'

import { lifecycle, branch, compose, renderNothing } from 'recompose'
import { getCurrentApp, getCurrentAppId } from 'e-submission/apps/selectors'
import OTPDialog from 'e-submission/containers/Dialog/OTP'
import { getAppStatus, getIsOnOtp } from 'e-submission/domain/data-model/form/selectors'
import { APP_STATUS } from 'e-submission/domain/data-model/constants'
import { isSelectedRemoteSelling } from './domain/data-model/form/selectors'

const redirect = (path) =>
  compose(
    withRouter,
    lifecycle({
      componentDidMount() {
        this.props.history.replace(path)
      },
    }),
    renderNothing
  )

const OTP = compose(
  connect((state) => {
    return {
      currentAppId: getCurrentAppId(state),
    }
  }),
  branch(({ currentAppId }) => _.isEmpty(currentAppId), redirect('/e-submission/list'))
)(() => <OTPDialog isOpen={true} backUrl="/e-submission/list" nextUrl="/e-submission/form/submit" />)

const routes = [
  {
    path: '/list',
    component: AppList,
  },
  {
    path: '/form/otp',
    component: OTP,
  },
  {
    path: '/form/:pageId/:subpage?',
    component: compose(
      connect((state) => {
        const app = getCurrentApp(state)
        return {
          currentAppId: getCurrentAppId(state),
          status: getAppStatus(app),
          isOnOtp: getIsOnOtp(app),
          remoteSelling: app && app.quickQuote ? isSelectedRemoteSelling(app) : false,
        }
      }),
      branch(({ currentAppId, fieldId }) => _.isEmpty(currentAppId), redirect('/')),
      branch(
        ({ status, isOnOtp, match }) => !_.endsWith(match.url, 'otp') && status === APP_STATUS.SIGNED && isOnOtp,
        redirect('otp')
      ),
      branch(({ status, match, remoteSelling }) => {
        return (
          remoteSelling &&
          !_.includes(match.url, 'video-consent') &&
          !_.includes(match.url, 'billing-method') &&
          !_.includes(match.url, 'remote-signature') &&
          !_.includes(match.url, 'submit') &&
          status === APP_STATUS.SIGNED
        )
      }, redirect('video-consent')),
      branch(
        ({ status, match, remoteSelling }) =>
          !remoteSelling && !_.includes(match.url, 'submit') && status === APP_STATUS.SIGNED,
        redirect('submit')
      ),
      branch(({ status, match }) => status === APP_STATUS.SUBMITTED, redirect('/e-submission/list'))
    )(AppForm),
  },
]

export default ({ match }) => (
  <App>
    <Dialog />
    <Switch>
      {routes.map(({ path, component }) => (
        <Route key={path} path={match.url + path} component={component} />
      ))}
      <Redirect from="/" to={match.url + '/form/insured'} />
    </Switch>
  </App>
)
